@mixin gradient-directional($start-color: #333, $end-color: #888, $deg: 45deg) {
    background-image: linear-gradient($deg, $start-color, $end-color);
    background-repeat: repeat-x;
}

.blue-gradient {
    @include gradient-directional($start-color: $blue-gradient-start, $end-color: $blue-gradient-end, $deg: -42deg);
}

.red-gradient {
    @include gradient-directional($start-color: $red-gradient-start, $end-color: $red-gradient-end, $deg: -42deg);
}

.yellow-gradient {
    @include gradient-directional($start-color: $yellow-gradient-start, $end-color: $yellow-gradient-end, $deg: -42deg);
}

.purple-gradient {
    @include gradient-directional($start-color: $purple-gradient-start, $end-color: $purple-gradient-end, $deg: -42deg);
}

.cyan-gradient {
    @include gradient-directional($start-color: $cyan-gradient-start, $end-color: $cyan-gradient-end, $deg: -42deg);
}

.skyblue-gradient {
    @include gradient-directional($start-color: $skyblue-gradient-start, $end-color: $skyblue-gradient-end, $deg: -42deg);
}