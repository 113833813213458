$bgColor           :#F8F8F8;
$primaryColor      :#004C93;
$black             :#000000;
$white             :#ffffff;
$fontBaseColor     :#212121;
$darkGray          :#888888;
$red               :#DC3545;
$gray-100          :#fafafc !default;
$gray-200          :#e9ecef;
$gray-300          :#dee2e6;
$gray-400          :#ced4da !default;
$gray-500          :#adb5bd !default;
$gray-600          :#6c757d !default;
$gray-700          :#495057 !default;
$gray-800          :#5f5f6b !default;
$gray-900          :#212529 !default;
$yellow: #FFCE1E;
$cyan: #00A1CB !default;
$purple: #6f42c1 !default;
$green: #007A34 !default;
$orange: #fd7e14 !default;

// Gradients
$red-gradient-start: #ff68a1 !default;
$red-gradient-end: #fe8866 !default;
$blue-gradient-start: #62ceff !default;
$blue-gradient-end: #0a98d7 !default;
$yellow-gradient-start: #ffce1e !default;
$yellow-gradient-end: #f7976d !default;
$purple-gradient-start: #f253ff !default;
$purple-gradient-end: #aa47ff !default;
$cyan-gradient-start: #9b5de6 !default;
$cyan-gradient-end: #00ecd8 !default;
$skyblue-gradient-start:#a2ffaf !default;
$skyblue-gradient-end:#00c9ff !default;

$baseFont          : 'Source Sans Pro', sans-serif;

$sm             : 576px;
$md             : 768px;
$lg             : 992px;
$xl             : 1200px;
$xxl            : 1400px;