@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;500;600;700&display=swap");

@import "reboot";
@import "grid";
@import "variables";
@import "gradients";
@import "material_base";

:root {
    font-size: 14px;
}

body,
.mat-typography {
    font-family: $baseFont !important;
    font-size: 1rem !important;
    background: $bgColor;
    color: $fontBaseColor;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-radio-button,
.mat-select,
.mat-form-field {
    font-family: $baseFont !important;
}

.mat-form-field-disabled {
    opacity: 0.7;

    .mat-form-field-flex {
        border: 1px solid #eee;

        .mat-select-placeholder {
            color: rgba(0, 0, 0, 0.42);
        }

        .mat-select-arrow-wrapper {
            opacity: 0.6;
        }
    }
}

img {
    max-width: 100%;
}

// Typography
p {
    font-size: 1rem;
    margin: 0 0 15px 0;

    &:last-child {
        margin-bottom: 0 !important;
    }
}

h1 {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
}

h2 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
}

h4 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
}

h5 {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

a {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

.h-100 {
    height: 100%;
}

.border-left {
    border-left: 1px solid $gray-300 !important;
}

.text-danger {
    color: $red;
}

.text-success {
    color: $green;
}

.text-primary {
    color: $primaryColor !important;
}

.text-seconday {
    color: $cyan !important;
}

.text-warning {
    color: $orange;
}

.border-top {
    border-top: 1px solid;
}

.border-blue {
    border-color: lighten($primaryColor, 60%);
}

.font-weight-bold {
    font-weight: 600;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.text-white {
    color: #fff !important;
}

.text-right {
    text-align: right;
}

.bg-primary {
    background-color: $primaryColor !important;
    color: $white;
}

.mw-auto {
    min-width: auto !important;
}

.pointer,
.cursor-pointer,
.clickable {
    cursor: pointer !important;
}

.w-100 {
    display: block;
    width: 100%;
}

.bg-light {
    background-color: $gray-100 !important;
}

.material-symbols-outlined {
    user-select: none;
    &.white {
        color: #ffffff;
    }
    &.dark-blue {
        color: #004c93;
    }
    &.blue {
        color: $cyan;
    }
    &.red {
        color: $red;
    }
    &.gray {
        color: #adb5bd;
    }
    &.green {
        color: $green;
    }
    &.size-18 {
        font-size: 18px;
        height: 18px;
        width: 18px;
    }
}

// Page wrapper style

.di-page-wrapper {
    padding: 30px 0;
}

.page-header {
    // margin-bottom: 20px;

    .di-heading-wrapper {
        h1 {
            font-size: 1.8rem;
            font-weight: 700;
            display: flex;
            align-items: center;
            margin-bottom: 0;

            .mat-chip {
                margin: 0 0 0 15px;
            }
        }

        h4 {
            font-size: 1.143rem;
        }
    }
}

.mat-flat-button {
    text-transform: uppercase;
}

.mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

// button styles

// button styles

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background-color: $primaryColor !important;
}

.mat-flat-button.mat-primary.mat-button-disabled {
    color: rgba($white, 0.5);
    background-color: rgba($primaryColor, 0.5) !important;
}

.mat-button-base,
button.mat-primary {
    font-size: 0.825rem;
    font-weight: 600;
    height: 35px;
    padding: 0 16px;
    text-transform: uppercase;
    background-color: #fff;
    color: $primaryColor;
    letter-spacing: 1px;

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .mat-icon,
        svg {
            font-size: 16px;
        }

        app-svg-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18px;
            width: 18px;
            margin-right: 6px;
        }
    }
}

.mat-stroked-button {
    background-color: $white;
    font-size: 0.825rem;
    height: 35px;
    padding: 0 20px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        app-svg-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18px;
            width: 18px;
            margin-right: 6px;
        }

        svg {
            line-height: 1;
            position: relative;

            path {
                fill: $primaryColor;
            }
        }
    }

    &.mat-primary {
        color: $primaryColor;
        border: 1px solid $primaryColor !important;

        &:hover {
            color: $primaryColor;
        }

        .material-symbols-outlined {
            color: $primaryColor;
        }

        svg {
            path {
                fill: $primaryColor;
            }
        }
    }

    &.mat-warn {
        color: $red;
        border: 1px solid $red !important;

        &:hover {
            color: $red;
        }

        .material-symbols-outlined {
            color: $red;
        }

        svg {
            path {
                fill: $red;
            }
        }
    }

    &[disabled] {
        // opacity: 0.6;
        border: 1px solid #cccccc !important;
        color: #cccccc !important;
    }
}

.mat-error {
    margin-top: -5px;
}

// radio selection

.di-radio-selection {
    .mat-radio-group {
        display: flex;
        flex-wrap: wrap;

        .mat-radio-button {
            margin: 0 10px 10px 0;

            .mat-radio-label {
                display: flex;
                align-items: center;
                padding: 5px 10px;
                background-color: $white;
                border-radius: 4px;
                font-family: $baseFont;
                text-transform: uppercase;
                border: 1px solid $primaryColor;
                color: $primaryColor;
                font-weight: 600;
                transition: all 0.3s;
                letter-spacing: 1px;

                .mat-radio-container {
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                }

                .mat-radio-label-content {
                    padding-left: 0;
                }

                &:hover {
                    background: $primaryColor;
                    color: $white;
                }
            }

            &.mat-radio-checked {
                .mat-radio-label {
                    background: $primaryColor;
                    color: $white;
                }
            }
        }
    }

    &.full-width {
        .mat-radio-group {
            flex-wrap: inherit;

            .mat-radio-button {
                width: 100%;

                &:last-child {
                    margin-right: 0;
                }

                .mat-radio-label {
                    justify-content: center;
                }
            }
        }
    }
}

.select-chips {
    .mat-chip-list-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        .mat-chip {
            margin: 0 10px 10px 0;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            background-color: $white !important;
            border-radius: 4px;
            font-family: $baseFont;
            text-transform: uppercase;
            border: 1px solid $primaryColor !important;
            color: $primaryColor !important;
            font-weight: 600;
            transition: all 0.3s;
            letter-spacing: 1px;
            height: 32px;
            cursor: pointer;

            &:hover {
                background: $primaryColor !important;
                color: $white !important;
            }
        }
    }
}

// year selection

.years-selection-list {
    ul {
        display: flex;
        flex-wrap: wrap;

        li:not(.last) {
            margin: 0 10px 10px 0;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            background-color: $white;
            border-radius: 4px;
            font-family: $baseFont;
            text-transform: uppercase;
            border: 1px solid $primaryColor;
            color: $primaryColor;
            font-weight: 600;
            transition: all 0.3s;
            letter-spacing: 1px;
            cursor: pointer;

            &:hover {
                background: $primaryColor;
                color: $white;
            }
        }

        li {
            .di-input-wrapper {
                .mat-form-field {
                    .mat-form-field-flex {
                        border: 1px solid $primaryColor;
                        width: 110px !important;

                        .mat-form-field-infix {
                            height: 30px;
                            padding: 7px 12px;

                            .mat-select-value,
                            .mat-select-placeholder {
                                color: $primaryColor !important;
                                font-weight: 600;
                            }

                            .mat-select-arrow {
                                top: 12px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-label {
    width: 100%;
    display: block;
    margin: 0 0 8px 0;
}

.di-input-wrapper {
    .mat-form-field {
        display: block;
        width: 100%;

        &.prefix {
            .mat-form-field-flex {
                .mat-form-field-infix {
                    padding-left: 30px;
                }
            }
        }

        &.suffix {
            .mat-form-field-flex {
                .mat-form-field-infix {
                    padding-right: 30px;
                }
            }
        }

        &.no_border {
            .mat-form-field-flex {
                border-color: transparent;
            }
        }

        .mat-form-field-flex {
            padding: 0;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            background: transparent !important;

            .mat-form-field-infix {
                padding: 10px 15px;
                height: 35px;
                border-top: 0;
                background: $white;
                border-radius: 4px;

                .mat-form-field-label-wrapper {
                    padding-top: 0;
                    top: 0;
                }
            }
        }

        .mat-form-field-underline {
            display: none;
        }

        &.mat-form-field-invalid {
            .mat-form-field-flex {
                border-color: #f44336;
            }
        }

        .mat-form-field-suffix {
            position: absolute;
            right: 7px;
            top: 7px;
            z-index: 10;

            button {
                width: 24px !important;
                height: 24px !important;

                svg {
                    height: 16px !important;
                    width: 16px !important;
                }
            }
        }

        .mat-form-field-prefix {
            position: absolute;
            left: 7px;
            top: 7px;
            z-index: 10;
        }

        &.textarea {
            .mat-form-field-flex {
                padding: 2px 0;
                overflow: hidden;

                .mat-form-field-infix {
                    height: auto;
                    padding: 0;

                    textarea {
                        background: $white;
                        padding: 10px 15px;
                        min-height: 100px;
                    }
                }
            }
        }

        &.regis-field {
            .mat-form-field-subscript-wrapper {
                height: 37px;
                position: absolute;
                top: 0;
                margin: 0;

                .mat-form-field-hint-wrapper {
                    height: 37px;
                }
            }

            .mat-hint {
                top: 6px;
                left: 6px;
                font-size: 0.9rem;
                background: $fontBaseColor;
                color: $white;
                width: max-content;
                border-radius: 3px;
                padding: 5px 10px;
                position: absolute;
            }

            .mat-form-field-infix {
                padding-left: 60px;
                position: relative;
                z-index: 1;
            }
        }

        &.hint {
            .mat-form-field-subscript-wrapper {
                position: relative;

                .mat-hint {
                    font-size: 14px;
                    color: #212121;
                }
            }
        }

        .mat-form-field-suffix {
            .mat-datepicker-toggle {
                position: absolute;
                top: -10px;
                right: 0;
            }
        }
    }

    &.action-btns {
        margin-bottom: 30px;
    }
}

.mat-form-field-appearance-fill {
    .mat-form-field-subscript-wrapper {
        padding: 0 !important;
    }
}

// Steps styles

.di-steps-wrapper {
    margin: 30px 0 0 0;
}

.di-step-wrapper {
    background: $white;
    box-shadow: 0px 0px 16px rgba($black, 0.1);
    border-radius: 5px;
    padding: 30px;
    position: relative;
    min-height: 150px;

    .step-heading {
        display: flex;
        align-items: center;
        font-size: 1.429rem;
        font-weight: 600;
        margin: 0 0 40px 0;

        .material-symbols-outlined {
            margin-right: 10px;
        }
    }

    .section-loader {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 0.8rem;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        min-height: 150px;

        .mat-spinner {
            height: 32px !important;
            width: 32px !important;

            svg {
                height: 32px !important;
                width: 32px !important;
            }
        }
    }

    .bottom_link {
        a {
            color: $primaryColor;
        }
    }

    .car-result-box {
        padding: 25px;
        background: #f9f9f9;
        border-radius: 5px;
    }
}

.di-vehicle-selection {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 30px;

    .di-vehicle-description {
        max-width: 300px;

        h2 {
            font-size: 1.286rem;
            line-height: 1.4;
            font-weight: 600;
            margin: 0 0 5px 0;
            color: $primaryColor;
        }

        h3 {
            font-size: 1.286rem;
            font-weight: 400;
            margin: 0 0 10px 0;
            line-height: 1.2;
        }
    }

    .di-vehicle-stats {
        .stat {
            font-size: 1.143rem;
            margin: 0 0 4px 0;
            display: flex;

            &:last-child {
                margin-bottom: 0;
            }

            label {
                min-width: 120px;
                position: relative;
                margin-right: 10px;
                color: rgba($black, 0.5);

                &:before {
                    content: ":";
                    position: absolute;
                    right: 0;
                }
            }
        }
    }

    .di-action {
        button {
            min-width: 100%;
        }
    }

    &.loading-description {
        h2.animatingLayer {
            height: 25px;
            max-width: 240px;
            margin: 0 0 20px 0;
        }

        .di-vehicle-stats {
            .stat {
                width: 260px;
                margin: 0 0 10px 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .di-action {
            .animatingLayer {
                max-width: 150px;
                height: 35px;
                border-radius: 2px;
            }
        }
    }
}

.animatingLayer {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #efebf9;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(8%, #efebf9),
        color-stop(18%, #e2dbf4),
        color-stop(33%, #efebf9)
    );
    background: -webkit-linear-gradient(left, #efebf9 8%, #e2dbf4 18%, #efebf9 33%);
    background: linear-gradient(to right, #efebf9 8%, #e2dbf4 18%, #efebf9 33%);
    -webkit-background-size: 800px 104px;
    background-size: 800px 104px;
    position: relative;
    height: 12px;
}

.loading-skeleton {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
        margin: 0 10px 10px 0;
        border-radius: 3px;
        width: 60px;
        height: 32px;
    }
}

.app-loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    mat-spinner,
    svg {
        width: 50px;
        height: 50px;
    }
}

.loading-valuation {
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .mat-spinner,
    svg {
        width: 50px !important;
        height: 50px !important;
    }

    .mat-spinner {
        margin: 0 0 15px 0;
    }
}

.toast-container {
    .toast {
        position: fixed;
        padding: 15px;
        border-radius: 5px;
        right: 20px;
        bottom: 20px;

        &.toast-error {
            background: $red;
            color: $white;
        }

        &.toast-success {
            background: $green;
            color: $white;
        }
    }
}

.selected_vehicles {
    padding: 20px 45px;
    background: $white;
    box-shadow: 0 0 15px rgba($black, 0.3);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

// Modal design

.mat-dialog-container {
    position: relative;
    max-width: 800px;
    min-width: 550px;
    width: 100%;
    background: #f8f8f8 !important;

    .mat-dialog-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font:
            600 20px/32px "Source Sans Pro",
            sans-serif;
        line-height: 1;

        button {
            background: transparent;
            border: 0;
            cursor: pointer;
        }
    }

    // RV Calculator popup styles
    .mat-dialog-content {
        overflow-x: hidden !important;

        &.rv_calculator {
            min-width: 700px;
            max-width: 820px;

            .vehicle_search_container {
                .mat-form-field {
                    .mat-form-field-wrapper {
                        height: 20px;
                        padding-bottom: 0 !important;

                        .mat-form-field-infix {
                            height: 20px;
                            padding: 0;
                            margin: 0;
                            border-top: 0;

                            .mat-form-field-label-wrapper {
                                height: 20px;

                                .mat-form-field-label {
                                    top: 0.9rem;
                                }
                            }
                        }

                        .mat-form-field-underline {
                            bottom: 0;
                        }
                    }
                }
            }

            .search_filters {
                .col {
                    margin-bottom: 0.5rem;

                    .mat-form-field {
                        width: 100%;
                    }
                }

                .btn {
                    min-height: 2.6rem;
                    margin-top: 0.4rem;
                }
            }

            .search_results {
                margin-top: 1.5rem;
                position: relative;

                .vehicle_breif_component {
                    margin-top: 0.8rem;
                }

                position: relative;

                .manage_recommendation {
                    position: absolute;
                    right: 0;
                    z-index: 100;
                }
            }
        }

        &.link_vehicle_popup {
            min-width: 800px;
            max-width: 800px;
            position: relative;

            .vehicle_breif_component {
                margin-bottom: 1rem;
            }

            .vehicle_list_filter {
                padding-left: 0.5rem;

                .filter_component {
                    min-width: 230px;
                    max-width: 230px;
                }
            }
        }

        &.confirmation_popup {
            max-width: 550px;
            position: relative;

            .close_btn {
                position: absolute;
                top: 0;
                right: 15px;
                background: transparent;
                border: 0;
                cursor: pointer;
            }

            h2 {
                margin: 1.5rem 0;
                line-height: 2rem;
            }

            .confirm_btns {
                .btn {
                    min-width: 150px;
                }
            }
        }

        &.add_company_popup {
            min-width: 650px;
            max-width: 650px;
            background: #f7f7f7 !important;
            padding: 2rem !important;

            .company_roles {
                .mat-checkbox {
                    width: 50%;
                    margin-top: 1.5rem;
                    display: inline-block;

                    .mat-checkbox-inner-container {
                        width: 24px !important;
                        height: 24px !important;

                        .mat-checkbox-frame {
                            border-radius: 100% !important;
                        }
                    }

                    .mat-checkbox-background,
                    .mat-checkbox-frame {
                        border-radius: 100%;
                        border-width: 1px;
                    }
                }
            }

            .counter_input {
                .counter {
                    font-size: 1.2rem;
                    font-weight: 500;
                    min-width: 30px;
                    text-align: center;
                    margin: 0 10px;
                }
            }
        }

        &.add_product {
            min-width: 650px;
            max-width: 650px;
            background: #fff !important;
            padding: 2rem !important;
        }

        &.invite_user {
            padding: 2rem !important;
            min-width: 500px !important;
            max-width: 500px !important;

            .mat-form-field {
                margin-bottom: 1rem;

                .mat-form-field-prefix {
                    .material-symbols-outlined {
                        svg {
                            margin-top: -10px;
                        }
                    }
                }

                .mat-form-field-infix {
                    width: auto !important;
                    min-width: auto !important;
                    margin-left: 1rem;
                }
            }
        }

        &.new_admin_link_popup {
            padding: 0 !important;
        }

        .search_results {
            position: relative;
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.mat-tooltip {
    margin-top: 0 !important;
}

@media only screen and (max-width: $xxl) and (min-width: $xl) {
    .container-xxl {
        padding: 0 30px;
    }
}

.mat-form-field-appearance-fill {
    .mat-form-field-subscript-wrapper {
        padding: 0 !important;
    }
}

.mat-standard-chip {
    border-radius: 5px !important;
}

.app_container {
    padding: 30px 0 !important;
}

.bg-white {
    background: #ffffff;
}

.shadow {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
}

.shadow-sm {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
}

.shadow-md {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
}

.shadow-lg {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
}

.dot {
    position: relative;
    display: inline-block;
    margin: 0.3rem 5px 0 5px;
    border-radius: 5px;
    height: 5px;
    width: 5px;
    background: #ddd;
}

.no_record_found {
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
        font-size: 1.2rem;
        opacity: 0.5;
    }
}

.thumbnail_loader {
    width: 100%;
    border-radius: 4px;
}

// Tooltip style
.tooltip_parent {
    position: relative;

    .tooltip_container {
        position: absolute;
        min-width: max-content;
        max-width: 300px;
        border-radius: 5px;
        background: #212121;
        padding: 6px 12px;
        color: #fff;
        z-index: 999;
        top: 20px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s;

        .linked_info {
            margin-top: 0.6rem;
            line-height: 1rem;
            color: #a6a6c1;
        }

        .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
            color: #fff !important;
        }
    }

    &:hover {
        .tooltip_container {
            opacity: 1;
            visibility: visible;
        }
    }
}

.mat-tab-body-content {
    overflow: hidden !important;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
    vertical-align: middle;

    div.sorting {
        display: flex;
        align-items: center;
        cursor: pointer;

        .mat-select-arrow {
            top: 5px !important;
            margin-left: 0.5rem !important;
        }

        &.desc {
            .mat-select-arrow {
                transform: rotate(180deg);
            }
        }
    }
}

vehicle-brief {
    &.bg-primary {
        .vehicle_breif_component {
            background: $primaryColor !important;
            color: $white !important;
            border-color: $primaryColor !important;

            .mat-chip-list-wrapper {
                .mat-chip {
                    background: transparent !important;
                    color: $white !important;

                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
}

.mat-table {
    width: 100%;
}

// table style

.custom-table {
    width: 100%;
    box-shadow: 0px 0px 12px rgba($black, 0.1) !important;
    border-radius: 6px !important;
    font-size: 14px;
    overflow: hidden;

    tr.mat-header-row,
    tr.mat-footer-row,
    tr.mat-row {
        width: 100%;
    }

    tr.mat-row.loading {
        position: relative;

        &:after {
            content: "Please wait...";
            position: absolute;
            z-index: 10;
            background: #eee;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    th {
        padding: 0 10px !important;
        line-height: 1.1;
        font-weight: 600;
        font-size: 0.9rem;
    }

    td {
        padding: 10px !important;
        line-height: 1.1;
        min-width: max-content;

        .description {
            margin: 10px 0 0 0;
        }

        .mat-chip-list {
            margin: 2px 0 0 0;
            display: block;

            .mat-chip-list-wrapper {
                .mat-blue {
                    background: #1ea9d5;
                    color: $white;
                }

                .mat-black {
                    background: #555555;
                    color: $white;

                    &.moreMakes {
                        cursor: pointer;
                    }
                }
            }

            &.small {
                .mat-chip {
                    font-size: 12px !important;
                    padding: 5px 10px !important;
                    margin: 0 2px;
                }
            }
        }

        .product-details {
            padding: 10px 0;
        }
    }
}

.actions {
    display: flex;
    align-items: center;

    a {
        .material-icons {
            font-size: 22px;
            height: 22px;
            width: 22px;
            color: #333;
        }
    }

    .btn-link {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 10px 0 0;
        color: $primaryColor;
        cursor: pointer;
        text-decoration: none;
        background-color: transparent;
        border: 0;
        outline: none;

        .material-icons {
            margin: 0 4px 0 0;
            font-size: 20px;
            color: $primaryColor;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.table {
    &.table-borderless {
        width: 100%;

        tr th,
        tr td {
            padding: 6px !important;
        }
    }
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    color: $primaryColor !important;
    background: transparent !important;
    font-weight: 600;
    font-size: 15px !important;
    padding: 0 !important;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary.background {
    color: $white !important;
    background: $primaryColor !important;
    font-weight: 600;
    font-size: 15px !important;
    padding: 7px 12px !important;
}

.vehicle_brand.d-flex.align-items-center {
    flex-wrap: nowrap;
    min-width: max-content;
    position: relative;
    top: -3px;
}

@media screen and (max-width: $sm) {
    body {
        padding-bottom: 75px;
    }

    .container-xxl {
        padding: 0 15px;
    }

    .di-page-wrapper {
        padding: 10px 0;
    }

    .di-step-wrapper {
        padding: 20px;
        min-height: auto;

        .step-heading {
            font-size: 1.2rem;
            margin: 0 0 20px 0;
            align-items: flex-start;
        }
    }

    .di-steps-wrapper {
        margin: 20px 0 0 0;

        .steps-row {
            flex-direction: column-reverse;
        }
    }
}

.mat-form-field.mat-paginator-page-size-select {
    margin: 0 !important;
    .mat-select-arrow {
        top: 5px !important;
    }
    .mat-form-field-infix {
        height: 52px !important;
    }
}
